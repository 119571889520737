import { useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Collapse from '@material-ui/core/Collapse';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MuiButton from '@material-ui/core/Button';

import { treeS3Items } from 'src/utils/S3Helper';
import { usePortalContext } from 'src/state/context/PortalContext';
import { COMMON_ERRORS, SNACKBAR } from 'src/utils/constants/app';
import { useCognito } from 'src/aws/Cognito';

import { STATIC_MENU } from './constants';

import './AwsTreeView.css';

const AwsTreeView = () => {
  const { setFolders } = usePortalContext();

  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isAuthenticated, checkAccess } = useCognito();

  const isGuest = !isAuthenticated || isAuthenticated && checkAccess('guest');

  const [treeViewItems, setTreeViewItems] = useState([]);

  const currentLocation = useMemo(() => {
    if (history.action === 'POP') {
      return encodeURI(location.pathname) + decodeURI(location.hash);
    }

    return decodeURI(encodeURI(location.pathname)) + decodeURI(location.hash);
  }, [location.pathname, location.hash, history.action]);

  useEffect(() => {
    const getAsyncNodes = async () => {
      try {
        const items = await treeS3Items('', {
          includeEmptyFolders: false
        });
        setFolders(items);
        setTreeViewItems([...STATIC_MENU, ...items]);
      } catch (error) {
        if (error.name === 'RequestTimeTooSkewed') {
          enqueueSnackbar(COMMON_ERRORS.invalidSignature, {
            ...SNACKBAR.defaultOptions,
            variant: 'error',
            persist: true,
            action: (key) => (
              <MuiButton
                variant="text"
                color="inherit"
                size="small"
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                Ok
              </MuiButton>
            ),
          });
        }
      }
    };

    getAsyncNodes();
  }, [setFolders]);

  const handleLeftMenuClick = (item) => {
    if (item.isFolder) {
      const newItems = Object.assign([], treeViewItems);
      const index = newItems.findIndex(
        (x) => x.name === item.name && x.id === item.id
      );
      newItems[index].opened = !item.opened;
      setTreeViewItems(newItems);
    } else {
      handlePageClick('', item);
    }
  };

  const handlePageClick = (parentName, item) => {
    let path = item.name;
    if ((parentName?.length ?? 0) > 0) {
      path = parentName + '/' + item.name;
    } else {
      if (item.name === 'Welcome') {
        history.push('/welcome');
        return;
      } else if (item.name === 'Search') {
        history.push('/search');
        return;
      } else if (item.name === 'Accelerator') {
        history.push('/accelerator');
        return;
      }
    }

    const encodedUrl = encodeURI(encodeURI(`/folder-viewer/${path}`));
    history.push(encodedUrl);
  };

  const getStyleClass = (item, parentName) => {
    if (item.isFolder) {
      return 'RegularLeftMenuItem';
    }

    let path = item.name;
    if ((parentName?.length ?? 0) > 0) {
      path = parentName + '/' + item.name;
    } else {
      if (item.name === 'Welcome') {
        return history.location.pathname === '/'
          ? 'SelectedLeftMenuItem'
          : 'RegularLeftMenuItem';
      } else if (item.name === 'Search') {
        return history.location.pathname === '/search'
          ? 'SelectedLeftMenuItem'
          : 'RegularLeftMenuItem';
      }
    }

    const selectedLocation = encodeURI(`/folder-viewer/${path}`);
    return currentLocation === selectedLocation
      ? 'SelectedLeftMenuItem'
      : 'RegularLeftMenuItem';
  };

  const renderPage = (item, parentName) => {
    return (
      <ListItem
        key={parentName + '_' + item.name}
        button
        className={getStyleClass(item, parentName)}
        onClick={() => handlePageClick(parentName, item)}
      >
        <ListItemText>{item.name}</ListItemText>
      </ListItem>
    );
  };

  const renderRoots = (item) => {
    if (item.hiddenForGuest && isGuest) return null;
    
    const childItemsMenu = !item.isFolder ? (
      ''
    ) : (
      <Collapse
        unmountOnExit
        in={item.opened}
        timeout="auto"
        className="NestedList"
      >
        <List disablePadding component="div">
          {item.children.map((x) => renderPage(x, item.name))}
        </List>
      </Collapse>
    );

    return (
      <div key={'div_' + item.name}>
        <ListItem
          key={item.id}
          button
          className={getStyleClass(item, '')}
          onClick={() => handleLeftMenuClick(item)}
        >
          <ListItemText>
            {item.name}
            {!item.isFolder ? null : item.opened ? (
              <KeyboardArrowDownIcon className="ExpColl" />
            ) : (
              <KeyboardArrowRightIcon className="ExpColl" />
            )}
          </ListItemText>
        </ListItem>
        {childItemsMenu}
      </div>
    );
  };

  if (treeViewItems.length === 0) {
    return <LinearProgress />;
  }

  return (
    <List component="nav" className="LeftMenuList">
      {treeViewItems.map(renderRoots)}
    </List>
  );
};

export default AwsTreeView;
