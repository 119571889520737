import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Hub } from '@aws-amplify/core';
import { HubCapsule } from '@aws-amplify/core/lib/Hub';
import { Auth } from 'aws-amplify';
import { SnackbarOrigin, useSnackbar } from 'notistack';

import MuiButton from '@material-ui/core/Button';

import { history } from 'src/router';
import { SNACKBAR } from 'src/utils/constants/app';

import { checkIfTimeExpired, checkUserAccess } from './utils';
import {
  COGNITO_CHALLENGE_NAME,
  VALIDATE_USER_TOKEN_INTERVAL,
} from './constants';
import {
  ChangePasswordOptions,
  CognitoConfig,
  CognitoConfigExtended,
  CognitoEvents,
  CognitoOptions,
  CognitoState,
  CognitoUserEntity,
  CompleteNewPasswordOptions,
  ForgotPasswordOptions,
  ResetPasswordOptions,
  SignInOptions,
  SignUpOptions,
  UpdateUserOptions,
} from './typings';

const defaultCognitoConfig: CognitoConfig = {
  user: null,
  signIn: () => Auth.federatedSignIn(),
  signOut: () => Auth.signOut(),
  refreshUser: () => {
    return;
  },
};

export const CognitoContext = createContext(defaultCognitoConfig);

const CognitoProvider: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [user, setUser] = useState<CognitoUserEntity>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarAnchor, setSnackbarAnchor] = useState<SnackbarOrigin>(
    SNACKBAR.defaultOptions.anchorOrigin as SnackbarOrigin
  );

  const isEmailVerified = useMemo(() => {
    const emailVerified = user?.attributes?.email_verified;

    return typeof emailVerified === 'string'
      ? emailVerified === 'true'
      : !!emailVerified;
  }, [user]);

  useEffect(() => {
    const userInit = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        setUser(null);
      }
    };

    userInit();
  }, []);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }: HubCapsule) => {
      switch (event) {
        case CognitoEvents.SIGN_IN:
          setUser(data);
          break;

        case CognitoEvents.SIGN_OUT:
          setUser(null);
          break;

        case CognitoEvents.ERROR:
          setUser(null);
          break;

        default:
          break;
      }
    });
  }, [user]);

  const cognitoConfig = useMemo(() => {
    const checkIsAuthenticated = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        return Boolean(user);
      } catch (error) {
        setUser(null);
        throw new Error(error.message);
      }
    };

    const checkIsAdmin = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const isAdmin = checkUserAccess(user, 'admins');

        return isAdmin;
      } catch (error) {
        setUser(null);
        throw new Error(error.message);
      }
    };

    const signIn = async ({ email, password, redirectTo }: SignInOptions) => {
      setIsLoading(true);
      await Auth.signOut();

      try {
        const user = await Auth.signIn(email, password);

        setUser(user);
        setIsLoading(false);

        const isNewPasswordRequired =
          user?.challengeName !== COGNITO_CHALLENGE_NAME.newPasswordRequired;

        if (isNewPasswordRequired && isEmailVerified) {
          window.location.reload();

          // NOTE: redirect doesn't work with window.location.reload
          // if (redirectTo) {
          //   history.push(redirectTo);
          // }
        }
      } catch (error) {
        setUser(null);
        console.error(error.message);
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
        setIsLoading(false);
      }
    };

    const signUp = async ({
      email,
      password,
      attributes,
      clientMetadata,
    }: SignUpOptions) => {
      setIsLoading(true);

      try {
        await Auth.signUp({
          username: email,
          password,
          attributes,
          clientMetadata,
        });

        history.push('/signin');
      } catch (error) {
        setUser(null);
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
        throw new Error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const signOut = async () => {
      setIsLoading(true);

      try {
        await Auth.signOut();

        history.push('/signin?action=signout');

        setUser(null);
      } catch (error) {
        console.error(error.message);
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
      } finally {
        setIsLoading(false);
      }
    };

    const forgotPassword = async ({ email }: ForgotPasswordOptions) => {
      setIsLoading(true);

      try {
        await Auth.forgotPassword(email);
      } catch (error) {
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
        throw new Error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const resetPassword = async ({
      email,
      verificationCode,
      password,
      redirectTo = '/signin',
    }: ResetPasswordOptions) => {
      setIsLoading(true);

      try {
        await Auth.forgotPasswordSubmit(email, verificationCode, password);

        history.push(redirectTo);
      } catch (error) {
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    const changePassword = async ({
      currentPassword,
      newPassword,
      redirectTo,
    }: ChangePasswordOptions) => {
      setIsLoading(true);

      try {
        await Auth.changePassword(user, currentPassword, newPassword);
        enqueueSnackbar('Password was changed successfully', {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'success',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.success,
        });

        if (redirectTo) {
          history.push(redirectTo);
        }
      } catch (error) {
        console.error(error.message);
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
      } finally {
        setIsLoading(false);
      }
    };

    const completeNewPassword = async ({
      user,
      newPassword,
      attributes,
    }: CompleteNewPasswordOptions) => {
      setIsLoading(true);

      try {
        await Auth.completeNewPassword(user, newPassword, attributes);

        history.push('/');
        window.location.reload();
      } catch (error) {
        console.error(error.message);
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
      } finally {
        setIsLoading(false);
      }
    };

    const refreshUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        setUser(user);
      } catch (error) {
        setUser(null);
      }
    };

    const updateUser = async ({ attributes }: UpdateUserOptions) => {
      setIsLoading(true);

      try {
        await Auth.updateUserAttributes(user, attributes);
        await refreshUser();
      } catch (error) {
        enqueueSnackbar(error.message, {
          ...SNACKBAR.defaultOptions,
          anchorOrigin: snackbarAnchor,
          variant: 'error',
          persist: false,
          resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        });
        throw new Error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    return {
      ...defaultCognitoConfig,
      user,
      isLoading,
      isEmailVerified,
      changePassword,
      checkIsAuthenticated,
      checkIsAdmin,
      forgotPassword,
      signIn,
      signUp,
      signOut,
      refreshUser,
      resetPassword,
      completeNewPassword,
      updateUser,
      setSnackbarAnchor,
    };
  }, [
    user,
    isLoading,
    isEmailVerified,
    enqueueSnackbar,
    snackbarAnchor,
    setSnackbarAnchor,
  ]);

  useEffect(() => {
    const validateUserToken = () => {
      if (!user) return;
      if (!user.signInUserSession) return;

      const expDate = user.signInUserSession.accessToken.payload['exp'];
      const isExpired = checkIfTimeExpired(expDate);

      if (isExpired) {
        cognitoConfig.signOut();

        enqueueSnackbar(
          'For security reasons, your session has expired. Please enter your credentials to log in again.',
          {
            ...SNACKBAR.defaultOptions,
            variant: 'info',
            persist: true,
            action: (key) => (
              <MuiButton
                variant="text"
                color="inherit"
                size="small"
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                Ok
              </MuiButton>
            ),
          }
        );
      }
    };

    const interval = setInterval(() => {
      if (user) validateUserToken();
    }, VALIDATE_USER_TOKEN_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [user, cognitoConfig, enqueueSnackbar, closeSnackbar]);

  return (
    <CognitoContext.Provider value={cognitoConfig}>
      {children}
    </CognitoContext.Provider>
  );
};

const useCognito = ({ snackbarAnchor }: CognitoOptions = {}): CognitoState => {
  const { user, setSnackbarAnchor, ...restCognitoContext } = useContext(
    CognitoContext
  ) as CognitoConfigExtended;

  const userAttributes = user?.attributes;

  const isAuthenticated = Boolean(user);

  const userFullName = useMemo(() => {
    return isAuthenticated
      ? `${userAttributes?.given_name} ${userAttributes?.family_name}`
      : '';
  }, [
    isAuthenticated,
    userAttributes?.given_name,
    userAttributes?.family_name,
  ]);

  const checkAccess = (group: string) => checkUserAccess(user, group);

  const isAdmin = checkAccess('admins');

  useEffect(() => {
    setSnackbarAnchor(
      snackbarAnchor || (SNACKBAR.defaultOptions.anchorOrigin as SnackbarOrigin)
    );
  }, [snackbarAnchor, setSnackbarAnchor]);

  return {
    ...restCognitoContext,
    user,
    userFullName,
    isAuthenticated,
    isAdmin,
    checkAccess,
    userData: {
      id: userAttributes?.sub,
      name: userAttributes?.name,
      email: userAttributes?.email,
      emailVerified: userAttributes?.email_verified,
      attributes: userAttributes,
    },
  };
};

export { useCognito };
export default CognitoProvider;
