import { FC, useMemo, useState } from 'react';

import { Scrollbars } from 'react-custom-scrollbars-2';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { usePortalView } from 'src/utils/hooks';
import AppHeader from 'src/components/AppHeader';
import AppFooter from 'src/components/AppFooter';
import SupportSection from 'src/components/SupportSection';
import InfoBanner from 'src/components/InfoBanner';
import { FOOTER, HEADER, SUPPORT_SECTION } from 'src/utils/constants/app';

import SidebarMenuLayout from './components/SidebarMenuLayout';
import { useStyles } from './styles';

const PrivateLayout: FC = ({ children }) => {
  const { isAdvanced, isProtected } = usePortalView();

  const classes = useStyles({ isAdvanced, isProtected });

  const [open, setOpen] = useState(false);

  const contentWrapperHeight = useMemo(() => {
    let offsetY = HEADER.height;
    if (!isProtected && isAdvanced) {
      offsetY += SUPPORT_SECTION.height;
    }
    return `calc(100vh - ${offsetY}px)`;
  }, [isAdvanced, isProtected]);

  const contentHeight = useMemo(() => {
    let offsetY = HEADER.height + FOOTER.height;
    if (!isProtected && isAdvanced) {
      offsetY += SUPPORT_SECTION.height;
    }
    return `calc(100vh - ${offsetY}px)`;
  }, [isAdvanced, isProtected]);

  const handleCloseInfoBanner = () => {
    localStorage.setItem('info-banner', 'false');
    setOpen(false);
  };

  // NOTE: should be uncommented if InfoBanner needed
  // useEffect(() => {
  //   const isInfoBannerVisible = localStorage.getItem('info-banner');

  //   if (isInfoBannerVisible && isInfoBannerVisible === 'true') {
  //     setOpen(true);
  //   } else if (!isInfoBannerVisible) {
  //     localStorage.setItem('info-banner', 'true');
  //     setOpen(true);
  //   }
  // }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.sidebar}>
        <AppHeader />
        {open && (
          <InfoBanner onClose={handleCloseInfoBanner}>
            {/* info banner content */}
          </InfoBanner>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {isAdvanced && <SidebarMenuLayout />}
          <Grid item xs={12} sm={isAdvanced ? 9 : 12}>
            <Grid container>
              {isAdvanced && !isProtected && (
                <Grid item xs={12}>
                  <SupportSection />
                </Grid>
              )}
              <Scrollbars
                autoHide
                className={classes.contentWrapper}
                style={{ height: contentWrapperHeight }}
              >
                <Box
                  className={classes.content}
                  style={{ minHeight: contentHeight }}
                >
                  {children}
                </Box>
                <AppFooter />
              </Scrollbars>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivateLayout;
