import { FC, useEffect } from 'react';

import { Route, RouteProps, useHistory } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import PrivateLayout from 'src/layouts/PrivateLayout';

const PrivateRoute: FC<RouteProps> = ({ children, ...restProps }) => {
  const history = useHistory();

  const { checkIsAuthenticated, signIn } = useCognito();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await checkIsAuthenticated();
      } catch (_error) {
        try {
          // GUEST LOGIN
          await signIn({
            email: process.env.REACT_APP_GUEST_USER_EMAIL as string,
            password: process.env.REACT_APP_GUEST_USER_PASSWORD as string,
          });
          await checkIsAuthenticated();
        } catch (__error) {
          history.push('/signin');
        }
      }
    };

    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIsAuthenticated, signIn]);

  return (
    <Route {...restProps}>
      <PrivateLayout>{children}</PrivateLayout>
    </Route>
  );
};

export default PrivateRoute;
