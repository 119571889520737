import { makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  isAdvanced: boolean;
  isProtected: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    height: '100vh',
    overflowY: 'hidden',
  },
  sidebar: {
    zIndex: 2,
  },
  contentWrapper: {},
  content: {
    padding: 45,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
}));
