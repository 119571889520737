import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

async function getAwsCreds() {
  return new Promise((resolve, reject) => {
    AWS.config.getCredentials((err) => {
      if (err) resolve(null);
      // credentials not loaded
      else resolve(AWS.config.credentials.accessKeyId);
    });
  });
}

export default async () => {
  try {
    const awsCreds = await getAwsCreds();

    if (awsCreds) {
      return;
    }

    const amplifyCreds = await Auth.currentCredentials();

    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      credentials: amplifyCreds,
    });

    AWS.config.getCredentials((err) => {
      if (err) console.error(err.stack); // credentials not loaded
    });
  } catch (error) {
    console.error(error);
  }
};
