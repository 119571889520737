import { FC, useEffect } from 'react';

import { Route, RouteProps, useHistory } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import PrivateLayout from 'src/layouts/PrivateLayout';

const signOutRedirect = '/signin?action=signout;';

const ProtectedRoute: FC<
  RouteProps & { roles?: ['admins']; restrictedRoles?: ['guest'] }
> = ({ children, roles = [], restrictedRoles = [], ...restProps }) => {
  const history = useHistory();

  const { checkAccess, checkIsAuthenticated } = useCognito();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await checkIsAuthenticated();

        for (const role of roles) {
          const hasRole = checkAccess(role);

          if (!hasRole) {
            history.push(signOutRedirect);
          }
        }

        for (const role of restrictedRoles) {
          const hasRole = checkAccess(role);

          if (hasRole) {
            history.push(signOutRedirect);
          }
        }
      } catch (_error) {
        history.push(signOutRedirect);
      }
    };

    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history.location, checkIsAuthenticated, checkAccess]);

  return (
    <Route {...restProps}>
      <PrivateLayout>{children}</PrivateLayout>
    </Route>
  );
};

export default ProtectedRoute;
