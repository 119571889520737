import { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faTh } from '@fortawesome/free-solid-svg-icons';

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { useCognito } from 'src/aws/Cognito';
import { checkArcAccess } from 'src/utils/CognitoIdentityHelper';
// import awsSdkConfigure from 'src/config/AwsSdkConfig';
import logo from 'src/assets/images/logo.png';

import './AppHeader.css';

const AppHeader = () => {
  const history = useHistory();

  const { user, userData, userFullName, isAdmin, signOut, isAuthenticated, checkAccess } =
    useCognito();

  const [isAdminPage, setIsAdminPage] = useState(false);
  const [arcLinks, setArcLinks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [componentsMenuAnc, setComponentsMenuAnc] = useState(null);

  // TODO: do we really need it?
  // useEffect(() => {
  //   awsSdkConfigure();
  // }, []);

  const isGuest = !isAuthenticated || isAuthenticated && checkAccess('guest');

  useEffect(() => {
    if (user) {
      setArcLinks(checkArcAccess(user));
    }
  }, [user]);

  useEffect(() => {
    setIsAdminPage(history.location.pathname.startsWith('/admin/'));
  }, [history.location.pathname]);

  const handleSignOut = () => {
    history.push('/signin?action=signout');
    setAnchorEl(null);
  };

  const handleGoToProfile = () => {
    history.push('/profile');
    handleClose();
  };

  const handleGoToHome = () => {
    history.push('/');
    handleClose();
  };

  const handleGoToLogin = () => {
    history.push('/signin');
    handleClose();
  };

  const handleGoToAdminUsers = () => {
    history.push('/admin/users');
    handleClose();
  };

  const handleGoToAdminPages = () => {
    history.push('/admin/pages');
    handleClose();
  };

  const nonAdminMenuItems = isAdmin && (
    <>
      <MenuItem onClick={handleGoToHome}>Home</MenuItem>
      <MenuItem onClick={handleGoToAdminPages}>Pages</MenuItem>
      <MenuItem onClick={handleGoToAdminUsers}>Users</MenuItem>
    </>
  );

  const result =
    userData !== null ? (
      <div>
        <MenuItem onClick={handleGoToProfile}>My Profile</MenuItem>
        {nonAdminMenuItems}
        <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
      </div>
    ) : (
      <div>
        <MenuItem onClick={handleGoToHome}>Home</MenuItem>
        <MenuItem onClick={handleGoToLogin}>Log In</MenuItem>
      </div>
    );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headerLogo =
    isAdminPage && isAdmin ? (
      <>
        <img className="Header-logo" src={logo} alt="Logo" />{' '}
        <span className="AdminHeader">| Admin</span>
      </>
    ) : (
      <img className="Header-logo" src={logo} alt="Logo" />
    );

  const isSelected = (adminType) => {
    return history.location.pathname.startsWith('/admin/' + adminType);
  };

  const usersClass = isSelected('users') ? 'selected' : 'regular';
  const pagesClass = isSelected('pages') ? 'selected' : 'regular';

  const adminRoutes = isAdminPage && isAdmin && (
    <div className="App-header-roures">
      <Link to="/admin/pages" className={pagesClass}>
        Pages
      </Link>
      <Link to="/admin/users" className={usersClass}>
        Users
      </Link>
    </div>
  );

  const goToArc = async (link) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    const refreshToken = session.getRefreshToken().getJwtToken();

    const a = document.createElement('a');
    a.target = '_blank';
    a.href = `${link.url}?accessToken=${accessToken}&idToken=${idToken}&refreshToken=${refreshToken}`;
    a.click();
  };

  return (
    <div className="App-header">
      <div>
        <Link className="logo-link" to="/">
          {headerLogo}
        </Link>
      </div>
      {adminRoutes}
      <div className="MenuWrapper">
        {arcLinks.length > 0 && (
          <div>
            <FontAwesomeIcon
              icon={faTh}
              onClick={(event) =>
                arcLinks.length > 0 && setComponentsMenuAnc(event.currentTarget)
              }
            />
            <Menu
              keepMounted
              id="simple-menu"
              anchorEl={componentsMenuAnc}
              open={Boolean(componentsMenuAnc)}
              onClose={() => setComponentsMenuAnc(null)}
            >
              {arcLinks.map((link, i) => (
                <MenuItem key={i} onClick={() => goToArc(link)}>
                  {link.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
        {!isGuest && (
          <div>
            <div
              className="UserMenu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {userFullName || 'Menu'}{' '}
              <FontAwesomeIcon className="MenuSortDown" icon={faSortDown} />
            </div>
            <Menu
              keepMounted
              id="user-right-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {result}
            </Menu>
          </div>
        )}
        {isGuest && (
          <Box mr={3}>
            <Typography style={{fontWeight: 600}}>
              <Link to="/signin?action=signout" style={{ color: 'white' }}>
                Log In
              </Link>
            </Typography>
          </Box>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
