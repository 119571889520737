export const STATIC_MENU = [
  { name: 'Welcome', id: 'Welcome', isFolder: false, hiddenForGuest: false },
  { name: 'Search', id: 'Search', isFolder: false, hiddenForGuest: false },
  {
    name: 'Accelerator',
    id: 'Accelerator',
    isFolder: false,
    hiddenForGuest: true,
  },
];
